.home-phrase {
    display: inline-block;
    padding: 10px 15px;
    /* background-color: rgba(240, 207, 207, 0.19); */
    border-radius: 15px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    /* color: linear-gradient(to top, #9795f0 0%, #fbc8d4 100%); */
    font-weight: 700;
    /* background-image:  #7c7aee ;     */
    -webkit-background-clip: text;
    background-clip: text;
    color: #B838F6;
}