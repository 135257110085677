.menulink {
    font-size: 1rem;
    font-style: normal;
    color: #938D9D;
    font-weight: 800;
    margin-top: 10px !important;
}

a.active {
    color: #B838F6;
}