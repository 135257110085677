/* .navbar { */
/* position: fixed;
top: 0;
left: 0;
right: 0; */
/* z-index: sticky; */
/* height: 80px; */
/* background-color: rgb(230, 239, 242); */
/* other styles */
/* } */

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    padding: 1rem;
    /* background-color: white; */
}

@media screen and (max-width: 767px) {
    .navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
    }

    .navbar > div {
        width: 100%;
    }
}
  