.shadow-box {
    padding: 2rem;
    border: 1px solid #E2E8F0;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
}

.shadow-box:hover,
.shadow-box:active {
    transform: scale(1.01);
}
