/* .root-layout {
    background-image: url('../images/main-banner-sun.svg');
>>>>>>> 42237da (modify homepage and inscribe)
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 5px;
    /* background-attachment: fixed; */
/* } */
.root-layout {
    background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
    background-size: cover;
    height: 100%;
    background-size: cover;
}

/* 新增的移动端样式 */
@media screen and (max-width: 768px) {
    .root-layout {
        padding: 16px; /* 调整内边距 */
    }
}
  