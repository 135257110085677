.btn-sticky-note {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    background-color: #F9AA33;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    color: #FFF;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: all 0.2s ease-in-out;
}

.btn-sticky-note:before {
    content: "";
    display: block;
    position: absolute;
    top: -25px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F9AA33;
    transform: rotate(-45deg);
    z-index: -1;
    transition: all 0.2s ease-in-out;
}

.btn-sticky-note:hover {
    background-color: #FFB347;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
}

.btn-sticky-note:hover:before {
    top: -50px;
    transform: rotate(-45deg) scale(1.2);
}
  